const authors = [
  {
    name: 'John Doe',
    imageUrl: 'john.jpg',
    bio:
      'John has been a front-end and UI designer fpr over 10 years, he is a brilliant artist',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://www.twitter.com/',
    instagram: 'https://www.instagram.com/',
    google: 'https://www.google.com/',
    linkedin: 'https://www.linkedin.com/',
  },
  {
    name: 'Jane Doe',
    imageUrl: 'jane.jpg',
    bio:
      'Jane is a back-end developer, she specializes in security and her favourite stack is the MERN stack',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://www.twitter.com/',
    instagram: 'https://www.instagram.com/',
    google: 'https://www.google.com/',
    linkedin: 'https://www.linkedin.com/',
  },
  {
    name: 'Yusuf Alper',
    imageUrl: 'jane.jpg',
    bio:
      'Jane is a back-end developer, she specializes in security and her favourite stack is the MERN stack',
    facebook: 'https://www.facebook.com/',
    twitter: 'https://www.twitter.com/',
    instagram: 'https://www.instagram.com/',
    google: 'https://www.google.com/',
    linkedin: 'https://www.linkedin.com/',
  },
]

module.exports = authors
